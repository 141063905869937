import React, { ReactNode, useEffect } from "react";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import Spinner from "src/layouts/components/spinner";
import { useAuth } from "src/hooks/useAuth";
import BlankLayout from "src/@core/layouts/BlankLayout";
import { getRouteWithParams } from "src/routes";
import useProjects from "src/hooks/useProjects";
import useKYCStatus from "src/hooks/useKYCStatus";

const Home: NextPage = () => {
  // ** Hooks

  const auth = useAuth();
  const { isProjectsFetching } = useProjects();
  const { isKYCFetching } = useKYCStatus();
  const router = useRouter();

  useEffect(() => {
    if (auth && auth.user && !isProjectsFetching && !isKYCFetching) {
      router.push(getRouteWithParams("dashboard"));
    } else {
      return;
    }
  }, [isProjectsFetching, isKYCFetching]);

  return <Spinner />;
};

export default Home;

Home.projectGuard = false

Home.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;
